<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id&expand=goodsCategory,goodsUnit"
    entity-name="商品清单"
    add-btn="新增商品"
    hide-show-detail
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
    :action="action"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="商品类别">
        <a-select
          style="width: 180px;"
          placeholder="选择商品类别查询"
          @change="updateList"
          :options="catOptions"
          v-model="form.goods_category_id"
          allowClear
          show-search
          option-filter-prop="children"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="商品名称/SKU">
        <a-input v-model="form.name" placeholder="输入商品名称/SKU查询" :maxLength="50" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="商品类别" prop="goods_category_id">
        <a-select
          placeholder="选择商品类别"
          :options="catOptions"
          v-model="form.goods_category_id"
          allowClear
          show-search
          option-filter-prop="children"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="商品名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入商品名称（最多50字）" :maxLength="50" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item label="商品单位" prop="goods_unit_id">
        <a-select
          placeholder="选择商品单位"
          :options="unitOptions"
          v-model="form.goods_unit_id"
          allowClear
          show-search
          option-filter-prop="children"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="商品图片">
        <form-upload v-model="form.img" uploadTip="请上传jpg、png、jpeg格式的图片，最多一张"></form-upload>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import { dataConvertOptions } from "../../common/js/tool";

export default {
  name: "CommodityList",
  data() {
    return {
      url: "/admin/asset-goods",
      searchKeyType: {
        name: 2,
      },
      columns: [
        { title: "SKU", dataIndex: "sku" },
        {
          title: "商品类别",
          dataIndex: "goodsCategory",
          customRender: (text) => {
            return text?.name || "-";
          },
        },
        { title: "商品名称", dataIndex: "name" },
        {
          title: "商品单位",
          dataIndex: "goodsUnit",
          customRender: (text) => {
            return text?.name || "-";
          },
        },
        { title: "更新时间", dataIndex: "update_time" },
        { title: "创建时间", dataIndex: "create_time" },
      ],
      rules: {
        goods_category_id: [{ required: true, message: "请选择商品类别", trigger: "change" }],
        name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        goods_unit_id: [{ required: true, message: "请选择商品单位", trigger: "change" }],
      },
      catOptions: [],
      unitOptions: [],
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {
    this.getCategory();
    this.getUnit();
  },
  methods: {
    getCategory() {
      this.$axios({
        url: "/admin/asset-goods-category",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.catOptions = dataConvertOptions(res.data);
      });
    },
    getUnit() {
      this.$axios({
        url: "/admin/asset-goods-unit",
        method: "GET",
        params: {
          sort: "-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.unitOptions = dataConvertOptions(res.data);
      });
    },
    updateList() {
      this.dataList.updateList();
    },
  },
};
</script>

<style lang="less" scoped></style>
